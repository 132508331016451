import React from 'react';
import styled from 'styled-components';
import checkmarkCircle from '../../images/checkmark-circle-blue.png';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const ThankyouWrapper = styled.div`
  margin: 0;

  li {
    transparent;
    list-style-type: none;
    margin: 0;
    padding: 0px 0px 10px 24px;
    vertical-align: middle;
    font-weight: 500;
    &:before {
      content: '*';
    }
    span {
      color: #349BD5;
    }
  }
`;

const Intro = styled.section`
  min-height: 100px;
  background-color: #d7edf7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0;
  min-height: 120px;
  p {
    font-size: 3.2em;
    text-align: center;
    color: #002e63;
    @media (max-width: 801px) {
      font-size: 3em;
    }
  }
  a {
    color: #002e63;
    text-decoration: none;
  }
`;

const Header = styled.header`
  background: #f2f2f2;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 3em;
    color: #b30000;
    text-align: center;
    margin: 0;
  }
`;

const Content = styled.section``;

const MidContent = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 264px;
  }

  div {
    width: 70%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
  h2 {
    margin-bottom: 10px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 40px;

  > div {
    width: 45%;
  }

  p,
  h4,
  img {
  }
  ul {
    display: block;
    margin-left: 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding-left: 0;
    padding: 10px;
    > div {
      width: 100%;
    }
  }
`;

const BottomSub = styled.div`
  display: flex;
  flex-wrap: wrap;
  img {
    width: 100px;
    height: 100px;
  }
  div {
    width: 95%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-style: ${props => props.secondary && 'italic'};
  font-weight: 500;
  span {
    color: #349bd5;
  }
  &.source {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 14px;
    width: 70%;
    @media (max-width: 801px) {
      padding-left: 5px;
    }
  }
  @media (max-width: 801px) {
    justify-content: space-between;
  }
`;

const EaagThankyou = () => (
  <ThankyouWrapper>
    <Intro>
      <p>
        Call Now! <a href="tel:8774467449">1-877-446-7449</a>
      </p>
    </Intro>
    <Header>
      <h1>Congratulations! We've Found A Match!</h1>
    </Header>
    <Content>
      <MidContent>
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/aagLogo.png"
          alt="Lendilio"
        />
        <div>
          <h2>
            AAG is America's #1 Reverse Mortgage Lender & Has Helped Over 1
            Million Retirees.
          </h2>
          <Text secondary>
            If you would like to speak with someone about your request
            immediately, <span>please call</span>
            {'  '}
            <a href="tel:8774467449">(877) 446-7449</a>.
          </Text>
        </div>
      </MidContent>
      <hr />
      <BottomContent>
        <div>
          <h4>
            Learn how an AAG reverse mortgage - or other home equity retirement
            solution - could help you build a more financially secure
            retirement.
          </h4>
          <ul>
            <li>Get Tax-Free Cash With This Loan</li>
            <li>
              Receive Money As A Line of Credit, Lump Sum, Or Monthly Payment
            </li>
            <li>Fund Home Improvements</li>
            <li>
              Stay In Your Home<span>*</span>
            </li>
            <li>
              Eliminate Monthly Mortgage Payments<span>*</span>
            </li>
          </ul>
          <Text>
            <span>*</span>Borrowers responsible for paying property taxes,
            homeowners insurance, and maintenance. Otherwise, the loan becomes
            due and payable and the property may be subject to foreclosure
          </Text>
        </div>

        <div>
          <BottomSub>
            <ReviewContainer>
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/aag-couple-1.png"
                alt="testimonial"
              />
              <Text>
                A reverse mortgage may be the best for you. The staff did an
                outstanding job of supporting my requests and responding to my
                questions. Superb effort all around. Thanks so much for your
                help. - Robert
              </Text>
            </ReviewContainer>
            <ReviewContainer>
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/aag-couple-2.png"
                alt="testimonial"
              />
              <Text>
                Great experience. Loan officer and processor were both friendly,
                professional and efficient. Worked diligently to overcome a few
                snags and we are very happy to have the security of knowing we
                can afford to comfortably remain in our home. Thanks for making
                this possible! - Sandra
              </Text>
            </ReviewContainer>
            <ReviewContainer>
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/aag-couple-3.png"
                alt="testimonial"
              />
              <Text>
                Our experience with American Advisors Group has been nothing
                short of amazing. Their customer service bent over backward to
                assist us in any questions we had. Everything was taken care of
                without much assistance from us. From the notary to the loan
                officer, it was an easy, fast and lucrative experience. - Marie
              </Text>
            </ReviewContainer>
            <Text className="source">Image Source Deposit Photos</Text>
          </BottomSub>
        </div>
      </BottomContent>
      <hr />
    </Content>
  </ThankyouWrapper>
);

export default withThankyouWrapper(EaagThankyou);
